import { Fragment, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loader from 'ui-component/Loader';
// import PageLoader from "../components/PageLoader";
// import AuthGuard from "../components/AuthGuard";

export function RenderRoutes(props) {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Routes>
          {props?.data?.map((route, i) => {
            const Component = route.component; //actuall page that has to be rendered on a specific route using lazy loading
            const Layout = route.layout || Fragment; //which layout has to we render with the page
            {
              /* const Guard = route.guard ? AuthGuard : Fragment; //authguard implementation to check weather ther user is loggedin or not */
            }
            return (
              <Route
                exact={route.exact}
                key={`route${i}`}
                path={route.path}
                element={
                  // <Guard>
                  <Layout>{route.routes ? <RenderRoutes data={route.routes} /> : <Component {...props} />}</Layout>
                  // </Guard>
                }
              />
            );
          })}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
