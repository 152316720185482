import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';
const DashboardDefault = Loadable(lazy(() => import('../../layout/MainLayout')));

export const routes = [
  // {
  //   exact: true,
  //   path: '/',
  //   // layout: LoginLayout,
  //   component: Loadable(lazy(() => import('../../views/pages/authentication/auth-forms/AuthLogin')))
  // },

  {
    exact: true,
    path: '/',
    layout: DashboardDefault,
    component: Loadable(lazy(() => import('../../views/pages/home')))
  },

  // Dashboard -- //

  // {
  //   exact: true,
  //   path: "*",
  //   element: lazy(() => import("../views/errors/NotFound")),
  // },

  {
    component: () => <Navigate to="/404" />
  }
];
